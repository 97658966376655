.msgboxxApp {
  margin: 0;
  font: 12px/1.21 "Helvetica Neue", arial, sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 100000;
  position: relative;
  box-sizing: border-box;
}

@media all and (min-width:0px) and (max-width: 480px) {
  .msgboxxlauncher img {
    height: 50px;
    z-index: 10;
  }
}

@media all and (min-width:481px) and (max-width: 10000px) {
  .launcher-size-normal img {
    height: 100px;
    z-index: 10;
  }

  .launcher-size-medium img {
    height: 75px;
    z-index: 10;
  }

  .launcher-size-small img {
    height: 50px;
    z-index: 10;
  }

  .launcher-size-small span {
    font-size: 1rem !important;
  }
}

.instructions-list {
  font-weight: bold;
  margin: 3px;

  font-size: 12px !important;
  text-align: left !important;
  line-height: 1.1 !important;
  letter-spacing: normal !important;
  color: black !important;
}

.msgboxxlauncher__close {
  background-color: #25d366;
  color: #ffffff;
  border: none;
  font-size: 1.7rem;
  height: 50px;
  width: 50px;
  border-radius: 50px !important;
  margin-top: 12px;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}


.msgboxxlauncher_cta {
  background-color: #444444;
  padding: 12px;
  border-radius: 24px;
  right: 24px;
  position: relative;
  z-index: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.msgboxxlauncher span {
  display: block;
  color: white;
  margin-left: 18px;
  font-size: 1.7rem;
}

.msgboxxlauncher span:first-child {
  font-weight: 800;
}

.msgboxxlauncher {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.msgboxxchat-window {
  background-color: white;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 40px rgb(0 0 0 / 16%);
  z-index: 9999;
  border-radius: 6px 6px 0 0;
}

.msgboxxchat-window__header {
  background-color: #075e54;
  color: white;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 6px 6px 0 0;
}

.msgboxxchat-window__header .msgboxx-title {
  margin-left: 18px;
  margin-top: 12px;
  color: white;
  padding: 0 !important;

  font-size: 12px !important;
  text-align: left !important;
  line-height: 1.1 !important;
  letter-spacing: normal !important;
}

.msgboxxchat-window__header img {
  border-radius: 30px;
}

.msgboxxchat-window__messages {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* background-color: #ece5dd;  */
}

.msgboxxchat-window__messages__cta {
  display: flex;
  margin-top: auto;
  margin-bottom: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.msgboxxchat-window__messages__options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.msgboxxchat-window__messages__options .msgboxx-button {
  background-color: #dcf8c6;
  color: black;
  border-radius: 16px;
  padding: 8px !important;
  font: 12px/1.21 "Helvetica Neue", arial, sans-serif !important;
  font-weight: 400;
  text-transform: none;
  border: none;
  cursor: pointer;
}

.msgboxxchat-window__messages__options .msgboxx-button:hover {
  opacity: 0.9 !important;
  background-color: #dcf8c6 !important;
  border-radius: 16px !important;
}

.msgboxxchat-window__messages__cta a {
  text-decoration: none;
}

.msgboxxchat-window__messages__user-message {
  border-radius: 12px;
  box-shadow: 0 12px 20px -10px rgb(0 188 212 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 188 212 / 20%);
  color: white;
  position: relative;
  background: #d81b60;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.msgboxxchat-window__messages__user-message::after {
  content: "";
  position: absolute;
  right: 0;
  top: 45%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: #d81b60;
  border-right: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-right: -13px;
}

.msgboxxchat-window__messages__message {
  padding: 12px 30px;
}

.msgboxxchat-window__messages__message img {
  border-radius: 30px;
  margin-right: 12px;
}

.msgboxxchat-window__messages__message {
  display: flex;
  align-items: center;
}

.msgboxxchat-window__messages__message p {
  padding: 12px;
  border-radius: 12px;
  background-color: #dcf8c6;
  /* box-shadow: 0 12px 20px -10px rgb(0 188 212 / 28%),
    0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 188 212 / 20%); */
  color: black;
  position: relative;
  font-size: 12px !important;
  text-align: left !important;
  line-height: 1.1 !important;
  letter-spacing: normal !important;
}

.msgboxxchat-window__messages__message p::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: #dcf8c6;
  border-left: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-left: -13px;
}

.msgboxxchat-window__send {
  background-color: #646464;
  margin-top: auto;
  padding: 6px;
  border-radius: 0 0 6px 6px;
  min-height: 48px;
  display: flex;
  gap: 6px;
}

.msgboxxchat-window__send input {
  background-color: white;
  height: 100%;
  flex: 3;
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
}

.msgboxxallowed {
  flex: 1;
  border-radius: 6px;
  background-color: #0fefef;
  color: black;
  border: none;
  cursor: pointer;
}

.msgboxxdisabled {
  flex: 1;
  border-radius: 6px;
  background-color: #0fefef;
  color: black;
  border: none;
  cursor: not-allowed;
}

.msgboxxmobile__close {
  display: none;
}

@media screen and (min-width: 480px) {
  .msgboxxContainer {
    position: fixed;
    bottom: 24px;
    right: 12px;
    max-width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .msgboxxchat-window {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 40px rgb(0 0 0 / 16%);
    z-index: 9999;
  }

  .msgboxxchat-window {
    border-radius: inherit;
  }

  .msgboxxlauncher span {
    font-size: inherit;
  }

  .msgboxxContainer {
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .msgboxxlauncher__close {
    display: none;
  }

  .msgboxxmobile__close {
    display: block;
    margin-left: auto;
    margin-right: 6px;
    border: none;
    background: none;
    color: #0fefef;
    font-size: 1.3rem;
  }
}

.msgboxxbounce {
  -webkit-animation: bounce 2s 2s;
  animation: bounce 2s 2s;
}

.msgboxx-qr-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 14px;
}

.msgboxx-qrcode {
  width: 125px;
  height: 125px;
}

.msgboxx-qrcode path[fill='#000000'] {
  fill: #000000 !important;
}

.msgboxx-qrcode path[fill='#FFFFFF'] {
  fill: #FFFFFF !important;
}

.msgboxx-qrbuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
}

/* Scroll down indicator (bouncing) */
@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}